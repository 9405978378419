import React, { useState, useEffect, useLayoutEffect } from "react";
import HomeBanner from "../Component/Home/HomeBanner";
import HomeAboutus from "../Component/Home/HomeAboutus";
import KeyFeatures from "../Component/Home/KeyFeatures";
import HomeContactus from "../Component/Home/HomeContactus";

function HomePage() {
  

  return (
    <>
      
      <section>
        <HomeBanner />
      </section>
      <section>
        <HomeAboutus/>
      </section>
      <section  id="key-features">
        <KeyFeatures/>
      </section>
      <section>
        <HomeContactus />
      </section>
    </>
  );
}

export default HomePage;
