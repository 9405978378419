import React, { useState, useEffect, useLayoutEffect, MouseEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import HomeBannerImage from '../../assets/images/banner-image.png';

function HomeBanner() {
    const navigate = useNavigate();

    // Handle the click event on the "Start your free trial" link
    const handleStartTrialClick = (event: MouseEvent<HTMLAnchorElement>) => {
        const auth = getAuth(); // Get the current auth instance
        if (!auth.currentUser) {
            event.preventDefault(); // Prevent default link behavior if not logged in
            navigate('/login'); // Redirect to the login page
        } else {
            // If the user is logged in, allow the link to navigate to /views
            navigate('/views'); // Redirect to views page
        }
    };

    return (
        <section className="home-page-main-banner" id="home-banner">
            <div className="home-page-banner-inner-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="main-banner-text">
                                <h1 className="wow fadeInUp" data-wow-duration="1500ms">Your ultimate AI-powered teaching companion is here</h1>
                                <p className="wow fadeInUp" data-wow-duration="1700ms">An AI powered platform to assist teachers with lesson planning, assignment generation and creative fun activities.</p>
                                <div className="main-banner-btn wow fadeInUp" data-wow-duration="1900ms">
                                    <Link className="solid-btn" to="/views" onClick={handleStartTrialClick}>
                                        Start your free trial &nbsp;<i className="fa-solid fa-arrow-right-long"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6">
                            <div className="banner-right-side-image">
                                <img src={HomeBannerImage} alt="Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeBanner;
