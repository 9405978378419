import React, { useState } from "react";
import Swal from 'sweetalert2';
import { FormData } from "../../types"; // Adjust the path to your types file

function ContactForm() {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check for swear words in the message
    const swearWords = ["swearword1", "swearword2", "swearword3"]; // Replace with actual words
    const containsSwearWord = swearWords.some((word) =>
      formData.message.toLowerCase().includes(word)
    );

    if (containsSwearWord) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please refrain from using inappropriate language in your message.',
      });
      return; // Stop form submission
    }

    // Call the function to send the email
    await sendEmail(formData);
  };

  const sendEmail = async (data: FormData) => {
    const proxyUrl = 'https://cors-anywhere.herokuapp.com/'; // CORS proxy
    const targetUrl = 'https://unifiedvariables.in/dev/sendgrid-email-test/send_email.php'; // SendGrid API endpoint

    try {
      const response = await fetch(`${targetUrl}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer 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`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "to": "adm.fortest.2022@gmail.com",
          "from": "unifiedvariables@gmail.com",
          "subject": "New Contact Form Query",
          "message": `New Query.<br>First Name: ${data.firstName}\nLast Name: ${data.lastName}\nEmail: ${data.email}\nPhone: ${data.phone}\nMessage: ${data.message}`  
        }),
      });

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Your message has been sent successfully!',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Opps!',
          text: 'Something went wrong!. Please try again later.',
        });
      }
    } catch (error) {
      console.error('Error sending email:', error);
      Swal.fire({
        icon: 'error',
        title: 'Opps!',
          text: 'Something went wrong!. Please try again later.',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="contact-from-label-input-section">
        <label>First Name</label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="contact-from-label-input-section">
        <label>Last Name</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="contact-from-label-input-section">
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="contact-from-label-input-section">
        <label>Phone</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className="contact-from-label-input-section">
        <label>Message</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <div className="contact-us-submit-btn">
        <button type="submit">Submit</button>
      </div>
    </form>
  );
}

export default ContactForm;
