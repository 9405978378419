import React from 'react';
import { Form, Input, Button, Typography, Row, Col, Affix, Dropdown, Menu } from 'antd';
import { StarFilled, ArrowLeftOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from '@firebase/auth';
import TeacherProfileImage from './assets/images/teacher-profile-right-side-image.png'
import LoginFromBackgroundImage from './assets/images/login-from-background-image.png'
import GradeIcon from './assets/images/grade-icon.png'
import UserIcon from './assets/images/user-icon.png'
import CityIcon from './assets/images/city-icon.png'
import SubjectIcon from './assets/images/subject-icon.png'
import SchoolIcon from './assets/images/school-icon.png'
export interface AdditionalInfo {
  name: string;
  city: string;
  grade: string;
  subject: string;
  school: string;
}

interface AdditionalInfoFormProps {
  additionalInfo: AdditionalInfo;
  setAdditionalInfo: React.Dispatch<React.SetStateAction<AdditionalInfo>>;
  handleAdditionalInfoSubmit: (info: AdditionalInfo) => void;
}

const AdditionalInfoForm: React.FC<AdditionalInfoFormProps> = ({
  additionalInfo,
  setAdditionalInfo,
  handleAdditionalInfoSubmit
}) => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAdditionalInfo(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const userName = auth.currentUser?.displayName || 'User';
  const userInitials = userName ? userName.split(' ').map(name => name[0]).join('') : 'U';

  const userMenu = (
    <Menu>
      <Menu.Item key="1" icon={<ArrowLeftOutlined />} onClick={() => navigate('/views')}>
        Back
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <section className="login-form-section additionalinfo-form-section">
      <div className="login-from-background-image">
        <img src={LoginFromBackgroundImage} alt="" />
      </div>
      <div className="container">
      <div className="login-from-image-total-section">
          <div className="row align-items-center login-from-row-section">
            <div className="col-md-6">
              <Form className='login-form-card'
              >
                <Typography.Title className='login-from-card-title' level={4}>
                  Teacher Profile
                </Typography.Title>

                {/* <Row justify="center" style={{ marginBottom: 8 }}>
                  {[...Array(5)].map((_, index) => (
                    <StarFilled key={index} style={{ color: '#FFD700', fontSize: '24px', margin: '0 2px' }} />
                  ))}
                </Row> */}

                {/* <Typography.Paragraph style={{ textAlign: 'center', marginBottom: 24, fontStyle: 'italic', color: '#1890ff' }}>
                  "You're a star teacher! Keep shining and inspiring!"
                </Typography.Paragraph> */}
                    <div className="login-form-input-section">
                        <div className="input-field-icon">
                          <img src={UserIcon} alt="" />
                        </div>
                      <Form.Item required>
                        <Input className='login-form-input' name="name" value={additionalInfo.name} onChange={handleChange} placeholder='Name'/>
                      </Form.Item>
                    </div>
                    <div className="login-form-input-section">
                        <div className="input-field-icon">
                          <img src={CityIcon} alt="" />
                        </div>
                        <Form.Item required>
                          <Input className='login-form-input' name="city" value={additionalInfo.city} onChange={handleChange} placeholder='City'/>
                        </Form.Item>
                    </div>
                    <div className="login-form-input-section">
                      <div className="input-field-icon">
                        <img src={GradeIcon} alt="" />
                      </div>
                      <Form.Item required>
                        <Input className='login-form-input' name="grade" value={additionalInfo.grade} onChange={handleChange} placeholder='Grade'/>
                      </Form.Item>
                    </div>
                    <div className="login-form-input-section">
                      <div className="input-field-icon">
                        <img src={SubjectIcon} alt="" />
                      </div>
                      <Form.Item required>
                        <Input className='login-form-input' name="subject" value={additionalInfo.subject} onChange={handleChange} placeholder='Subject'/>
                      </Form.Item>
                    </div>
                    <div className="login-form-input-section">
                      <div className="input-field-icon">
                        <img src={SchoolIcon} alt="" />
                      </div>
                    <Form.Item required>
                      <Input className='login-form-input' name="school" value={additionalInfo.school} onChange={handleChange} placeholder='School'/>
                    </Form.Item>
                  </div>
                <Button className='login-from-login-btn' type="primary" block onClick={() => handleAdditionalInfoSubmit(additionalInfo)}>
                  Submit  &nbsp; <i className="fa-solid fa-arrow-right-long"></i>
                </Button>
              </Form>
            </div>
              <div className="col-md-6">
                <div className="login-form-right-side-image">
                  <img src={TeacherProfileImage} alt="" />
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdditionalInfoForm;
