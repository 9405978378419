import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, Button, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Space, Input } from 'antd';
import { ThumbUp, ThumbDown } from "@mui/icons-material";
import { SaveOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import FeedbackForm from './FeedbackForm';
import { saveAs } from 'file-saver';
import { getFirestore } from 'firebase/firestore';
import CopyLinkButton from './CopyComponent';
import { getAuth } from '@firebase/auth';
import 'katex/dist/katex.min.css';
import axios from 'axios';

const api_uri = process.env.REACT_APP_API_URI ?? "";

const { TextArea } = Input;

interface ResponseCardProps {
  initialResponse: string;
  topic: string;
  grade: string;
  card_id: string;
  board: string;
  additionalDetails: string;
  isLoading: boolean;
  setResponse: React.Dispatch<React.SetStateAction<string>>;
}

interface Response {
  query: string;
  content: string;
  isComplete: boolean;
  feedback?: 'like' | 'dislike' | null;
}

const ResponseCard: React.FC<ResponseCardProps> = ({
  initialResponse,
  topic,
  grade,
  card_id,
  board,
  additionalDetails,
  isLoading,
  setResponse
}) => {
  const db = getFirestore();
  const auth = getAuth();
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [exportLink, setExportLink] = useState('');
  const [loading, setLoading] = useState(isLoading);
  const [refineInput, setRefineInput] = useState('');
  const [responses, setResponses] = useState<Response[]>([]);
  const [showRefinementInput, setShowRefinementInput] = useState(false);
  const websocketRef = useRef<WebSocket | null>(null);

  const log = useCallback((message: string, data?: any) => {
    console.log(`[ResponseCard] ${message}`, data);
  }, []);

  useEffect(() => {
    log('Component mounted');
    return () => log('Component will unmount');
  }, [log]);

  useEffect(() => {
    setLoading(isLoading);
    log('isLoading changed', isLoading);
  }, [isLoading, log]);

  useEffect(() => {
    if (initialResponse) {
      log('Setting initial response', initialResponse);
      setResponses([{ query: 'Initial Query', content: initialResponse, isComplete: true }]);
    }
  }, [initialResponse, log]);

  useEffect(() => {
    log('Responses updated', responses);
    setShowRefinementInput(responses.some(res => res.isComplete));
  }, [responses, log]);

  const handleExportAsText = (text: string) => {
    log('Exporting as text');
    const blob = new Blob([text], { type: 'text/plain;charset=utf-8;' });
    saveAs(blob, 'response.txt');
  };

  const handleExportGoogleDrive = async (text: string) => {
    log('Exporting to Google Drive');
    let user = auth?.currentUser;
    try {
      if (user) {
        setLoading(true);
        const serverResponse = await axios.post(`${api_uri}/create-doc`, {
          firebase_user_id: user.uid,
          markdown_text: text,
        });

        const { document_url } = serverResponse.data;
        setExportLink(document_url);
        setExportDialogOpen(true);
      }
    } catch (error) {
      console.error("Error during Google Drive export:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFeedbackOpen = () => {
    log('Opening feedback dialog');
    if (auth.currentUser) {
      setFeedbackOpen(true);
    } else {
      console.error("User not authenticated");
    }
  };

  const handleFeedbackClose = () => {
    log('Closing feedback dialog');
    setFeedbackOpen(false);
  };

  const handleExportClose = () => {
    log('Closing export dialog');
    setExportDialogOpen(false);
  };
  const saveSignal = async (event: React.MouseEvent, signalType: 'like' | 'dislike', index: number) => {
    event.preventDefault();
    log(`Saving signal: ${signalType} for response at index ${index}`);

    setResponses(prevResponses => {
      const newResponses = [...prevResponses];
      newResponses[index].feedback = signalType === newResponses[index].feedback ? null : signalType;
      return newResponses;
    });

    // Here you would typically send this feedback to your backend
    // For example:
    // await sendFeedbackToBackend(responses[index].content, signalType);
  };

  const handleRefineSubmit = () => {
    log('Refine submit triggered', refineInput);
    if (!refineInput) {
      console.error('Refine input is required');
      return;
    }

    setLoading(true);
    setShowRefinementInput(false);

    const wsUri = process.env.REACT_APP_WS_URI || '';
    if (!wsUri) {
      console.error('WebSocket URI is not configured.');
      setLoading(false);
      setShowRefinementInput(true);
      return;
    }

    const websocket = new WebSocket(wsUri);
    websocketRef.current = websocket;

    setResponses(prev => {
      const newResponses = [...prev, { query: refineInput, content: '', isComplete: false }];
      log('Added new response to responses array', newResponses);
      return newResponses;
    });

    websocket.onopen = () => {
      log('WebSocket opened');
      const message = JSON.stringify({
        topic,
        grade,
        card_id,
        board,
        refineInput,
      });

      websocket.send(message);
    };

    websocket.onmessage = (event) => {
      log('WebSocket message received', event.data);
      const data = event.data.replaceAll('^^^', '\n');
      setResponses(prev => {
        const newResponses = [...prev];
        const lastResponse = newResponses[newResponses.length - 1];
        lastResponse.content += data;
        log('Updated response content', newResponses);
        return newResponses;
      });
    };

    websocket.onerror = (error) => {
      console.error('WebSocket error occurred:', error);
      setLoading(false);
      setShowRefinementInput(true);
    };

    websocket.onclose = () => {
      log('WebSocket closed');
      setResponses(prev => {
        const newResponses = [...prev];
        const lastResponse = newResponses[newResponses.length - 1];
        lastResponse.isComplete = true;
        log('Marked last response as complete', newResponses);
        return newResponses;
      });
      setLoading(false);
      setRefineInput('');
      setShowRefinementInput(true);
    };
  };

  const renderResponseCard = (res: Response, index: number) => {
    log(`Rendering response card for index ${index}`, res);
    return (
      <Card key={index} className="markdown-container" style={{ width: '100%', marginTop: '20px', padding: '0px', background: 'none',}}>
        <div className="query-text-export-btn-section">
          <div className="query-text-section">
            <h4 className='query-text'>Query: {res.query}</h4>
          </div>
          <div className="export-text-export-drive-btn">
              <Button
                // startIcon={<SaveOutlined />}
                onClick={() => handleExportAsText(res.content)}
                style={{ marginRight: '10px' }}
              >
                Export as Text
              </Button>
              <Button
                  // startIcon={<SaveOutlined />}
                  onClick={() => handleExportGoogleDrive(res.content)}
                >
                  Export to Google Drive
              </Button>
          </div>   
        </div> 
        <ReactMarkdown
          remarkPlugins={[remarkGfm, remarkMath]}
          rehypePlugins={[rehypeKatex]}
        >
          {res.content}
        </ReactMarkdown>
        {res.isComplete && (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <div>
              <IconButton
                onClick={(event) => saveSignal(event, 'like', index)}
                aria-label="like"
                style={{
                  backgroundColor: res.feedback === 'like' ? '#e6f7ff' : 'transparent',
                  color: res.feedback === 'like' ? '#1890ff' : 'inherit'
                }}
              >
                <ThumbUp />
              </IconButton>
              <IconButton
                onClick={(event) => saveSignal(event, 'dislike', index)}
                aria-label="dislike"
                style={{
                  backgroundColor: res.feedback === 'dislike' ? '#fff1f0' : 'transparent',
                  color: res.feedback === 'dislike' ? '#ff4d4f' : 'inherit'
                }}
              >
                <ThumbDown />
              </IconButton>
            </div>
            {/* <div>
              <button className="feedback-button" onClick={handleFeedbackOpen}>Give Feedback</button>
            </div> */}
          </div>
        )}
      </Card>
    );
  };

  return (
    <Space direction="vertical" style={{ marginTop: 20, width: '100%' }}>
      {responses.map((res, index) => renderResponseCard(res, index))}

      {showRefinementInput && !loading && (
        <>
          <div className="login-form-input-section">
            <TextArea className='login-form-input'
              value={refineInput}
              onChange={(e) => setRefineInput(e.target.value)}
              placeholder="Enter any refinements or further details for the response"
              rows={1}
              style={{ marginTop: 20, width: '100%' }}
            />
          </div>
          <div className="ask-praro-feedback-btn">
            <button className="feedback-button" onClick={handleFeedbackOpen}>Give Feedback</button>
            <Button className='ask-praro-btn'
              variant="contained"
              onClick={handleRefineSubmit}
            >
              Ask Praro &nbsp; <i className="fa-solid fa-arrow-right-long"></i>
            </Button>
          </div>
        </>
      )}

      <Dialog open={exportDialogOpen} onClose={handleExportClose} fullWidth maxWidth="sm">
        <div className="dialog-cancel-btn">
          <button onClick={handleExportClose}><i className="fa-solid fa-circle-xmark"></i></button>
        </div>
        <DialogTitle>Your document link</DialogTitle>
        <DialogContent>
          <div>
            <a href={exportLink} target="_blank" rel="noopener noreferrer">
              {exportLink}
            </a>
            <CopyLinkButton link={exportLink} />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={feedbackOpen} onClose={handleFeedbackClose} fullWidth maxWidth="sm">
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <FeedbackForm userTopic={topic} userGrade={grade} onClose={handleFeedbackClose} auth={auth} />
        </DialogContent>
      </Dialog>
    </Space>
  );
};

export default ResponseCard;