import React, { useEffect, useState, MouseEvent  } from 'react';
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from 'react-router-dom';
import { Card, Col, Row, Typography, Spin, Alert, Layout, Menu, Dropdown, Button, Affix } from 'antd';
import axios from 'axios';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { BookOutlined, CheckCircleOutlined, CustomerServiceOutlined, ReadOutlined, SmileOutlined, FileTextOutlined, LogoutOutlined, EditOutlined, UserOutlined, BulbOutlined } from '@ant-design/icons';
import AdditionalInfoForm, { AdditionalInfo } from '../../AdditionalInfoForm';
import ViewCardImage1 from '../../assets/images/our-key-features-icon1.png';
import ViewCardImage2 from '../../assets/images/our-key-features-icon2.png';
import ViewCardImage3 from '../../assets/images/our-key-features-icon3.png';
import ViewCardImage4 from '../../assets/images/our-key-features-icon4.png';
import ViewCardImage5 from '../../assets/images/our-key-features-icon5.png';
import ViewCardImage6 from '../../assets/images/our-key-features-icon6.png';
import ViewCardImage7 from '../../assets/images/our-key-features-icon7.png';
import { relative } from 'path';



const { Title, Paragraph } = Typography;

interface CardData {
  id: string;
  title: string;
  description: string;
  type: string;
  gradeLevel: string;
  numberOfQuestions: number;
  additionalInfo: string;
}
const iconMap: { [key: string]: { icon: JSX.Element; bgColor: string } } = {
  lesson_plan: { icon: <img src={ViewCardImage1} alt="" />, bgColor: '#21b573' },
  MCQ: { icon: <img src={ViewCardImage5} alt="" />, bgColor: '#21b573' },
  song: { icon: <img src={ViewCardImage4} alt="" />, bgColor: '#21b573' },
  summarizer: { icon: <img src={ViewCardImage2} alt="" />, bgColor: '#21b573' },
  activity: { icon: <img src={ViewCardImage3} alt="" />, bgColor: '#21b573' },
  assignment: { icon: <img src={ViewCardImage6} alt="" />, bgColor: '#21b573' },
  case_study: { icon: <img src={ViewCardImage7} alt="" />, bgColor: '#21b573' },
};
interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const NextArrow: React.FC<ArrowProps> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block', background: '#ddd' }} onClick={onClick}>
      <FaArrowRight />
    </div>
  );
};

const PrevArrow: React.FC<ArrowProps> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block', background: '#ddd' }} onClick={onClick}>
      <FaArrowLeft />
    </div>
  );
};

const key_features_slider = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

// interface KeyFeaturesProps {
//   sectionId: string;
// }

const KeyFeatures: React.FC = () => {
  const [cardData, setCardData] = useState<CardData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo | null>(null);
  const api_uri = process.env.REACT_APP_API_URI ?? "";
  const navigate = useNavigate();
  const analytics = getAnalytics();

  useEffect(() => {
    // document.title = 'Cards View';
    logEvent(analytics, 'page_view', { page: 'Cards View' });

    const fetchCardData = async () => {
      try {
        const response = await axios.get(`${api_uri}/cards`);
        setCardData(response.data);
      } catch (error) {
        console.error('Error fetching card data:', error);
        setError('Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCardData();
  }, [api_uri, analytics]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const auth = getAuth();
      const db = getFirestore();
      if (auth.currentUser) {
        const userDoc = doc(db, 'users', auth.currentUser.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserName(userData.name);
        }
      }
    };

    fetchUserInfo();
  }, []);

  const handleEditInfo = async () => {
    const auth = getAuth();
    const db = getFirestore();
    if (auth.currentUser) {
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data() as AdditionalInfo;
        setAdditionalInfo(userData);
        navigate('/edit-info', { state: { additionalInfo: userData } });
      }
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      setError('Error logging out.');
      console.error('Error logging out:', error);
    }
  };
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const auth = getAuth();
    if (!auth.currentUser) {
      event.preventDefault(); // Prevent the default link behavior
      navigate('/login'); // Redirect to login page
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Alert message={error} type="error" />
      </div>
    );
  }
  

  // Handle the click event on the "Start your free trial" link
  const handleStartTrialClick = (event: MouseEvent<HTMLAnchorElement>) => {
      const auth = getAuth(); // Get the current auth instance
      if (!auth.currentUser) {
          event.preventDefault(); // Prevent default link behavior if not logged in
          navigate('/login'); // Redirect to the login page
      } else {
          // If the user is logged in, allow the link to navigate to /views
          navigate('/views'); // Redirect to views page
      }
  };

  return (
    <section className="key-features-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="key-features-section-title" style={{position: 'relative', zIndex: '99',}}>
              <h2>Our Key Features</h2>
              <p>Start exploring today and transform your teaching experience with the ultimate companion by your side.  Explore resources for:</p>
              <div className="key-features-section-list-text">
                <ul>
                  <li>Building lesson plans</li>
                  <li>Generating assignments</li>
                  <li>Creating fun activities</li>
                </ul>
              </div>
              <p>
              The best part? You're in control. Use our materials as-is or personalize them to perfectly suit your teaching style and your students' unique needs.</p>
              <div className="about-us-section-btn">
                  <Link className="section-btn" to="/views" onClick={handleStartTrialClick}>Start your free trial &nbsp;<i className="fa-solid fa-arrow-right-long"></i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="key-features-slider-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 key-feature-col-section">
              <Slider {...key_features_slider}>
                  {cardData.map((card) => {
                      const cardTypeInfo = iconMap[card.id] || { icon: null, bgColor: '#fff' }; // Default bg color if type is not in iconMap
                      return (
                        <div className='item'  key={card.id}>
                          <Link 
                          to={`/detail/${card.id}?title=${card.title}`} 
                          style={{ textDecoration: 'none' }} 
                          aria-label={`View details for ${card.title}`}
                          onClick={handleClick}
                          >
                            <Card className='view-cards'
                              hoverable
                              style={{
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                height: '100%',
                                cursor: 'pointer',
                                backgroundColor: cardTypeInfo.bgColor,
                              }}
                            >
                              <div className='key-features-slider-card-icon-title'>
                                <div className="key-features-slider-icon">{cardTypeInfo.icon}</div>
                              <Card.Meta
                                title={<Title className='card-title-text' level={5}>{card.title}</Title>}
                              />
                              </div>
                              <Card.Meta
                                description={<Paragraph className='card-paragraph-text' ellipsis={{ rows: 6, expandable: false }}>{card.description}</Paragraph>}
                              />
                              <div className="key-features-slider-card-bottom-icon"><i className="fa-solid fa-arrow-right-long"></i></div>
                            </Card>
                          </Link>
                        </div>
                      );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div> */}
      <section className='key-features-card m-5'>
        <div className="container">
          <div className="row">
          <div className='row justify-content-center'>
                {/* {cardData.map((card) => {
                  const cardTypeInfo = iconMap[card.id] || { icon: null, bgColor: '#fff' }; // Default bg color if type is not in iconMap
                  return (
                    <div className='co-xl-3 col-lg-3 col-md-6 view-card-call-section'  key={card.id}>
                      <Link 
                      to={`/detail/${card.id}?title=${card.title}`} 
                      style={{ textDecoration: 'none' }} 
                      aria-label={`View details for ${card.title}`}
                      onClick={handleClick}
                      >
                        <Card className='view-cards'
                          hoverable
                          style={{
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            height: '100%',
                            cursor: 'pointer',
                            backgroundColor: cardTypeInfo.bgColor,
                          }}
                        >
                          <div className='key-features-slider-card-icon-title'>
                            <div className="key-features-slider-icon">{cardTypeInfo.icon}</div>
                          <Card.Meta
                            title={<Title className='card-title-text' level={5}>{card.title}</Title>}
                          />
                          </div>
                          <Card.Meta
                            description={<Paragraph className='card-paragraph-text' ellipsis={{ rows: 6, expandable: false }}>{card.description}</Paragraph>}
                          />
                          <div className="key-features-slider-card-bottom-icon"><i className="fa-solid fa-arrow-right-long"></i></div>
                        </Card>
                      </Link>
                    </div>
                  );
                })} */}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default KeyFeatures;
